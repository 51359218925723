const R = require('ramda');
const stagingApiEndpoint = process.env.REACT_APP_API_URL || 'https://api.staging.lendingone.com';

const config = {
  all: {
    app: 'bui',
    env: process.env.REACT_APP_ENV || 'development',
    isDev: process.env.NODE_ENV !== 'production',
    basename: process.env.PUBLIC_PATH,
    host: process.env.HOST || 'localhost',
    port: process.env.PORT || 3001,
    isBrowser: typeof window !== 'undefined',
    isServer: typeof window === 'undefined',
    apiUrl: 'http://api.lendingone.local:3000/v2',
    authUrl: 'http://api.lendingone.local:3000/oauth',
    fbAppId: '991453140998882',
    clientId: process.env.REACT_APP_CLIENT_ID || '',
    clientSecret: process.env.REACT_APP_CLIENT_SECRET || ',',
    environmentToken: '6f9ae2263da24b03b646524e5d5f1922',
  },
  test: {
    isProd: false,
  },
  development: {
    isProd: false,
    portfolioImportTemplateUrl: 'http://api.lendingone.local:3000/imports/examples/portfolio.xlsx',
    bridgeImportTemplateUrl: 'http://api.lendingone.local:3000/imports/examples/bridge_properties.xlsx',
    reScheduleImportTemplateUrl: 'http://api.lendingone.local:3000/imports/examples/re_schedule.xlsx',
  },
  staging: {
    isProd: false,
    host: process.env.HOST || 'localhost',
    port: process.env.PORT || 8080,
    apiUrl: `${stagingApiEndpoint}/v2`,
    authUrl: `${stagingApiEndpoint}/oauth`,
    portfolioImportTemplateUrl: `${stagingApiEndpoint}/imports/examples/portfolio.xlsx`,
    bridgeImportTemplateUrl: `${stagingApiEndpoint}/imports/examples/bridge_properties.xlsx`,
    reScheduleImportTemplateUrl: `${stagingApiEndpoint}/imports/examples/re_schedule.xlsx`,
  },
  integration: {
    isProd: false,
    host: process.env.HOST || 'localhost',
    port: process.env.PORT || 8080,
    apiUrl: 'https://api.integration.lendingone.com/v2',
    authUrl: 'https://api.integration.lendingone.com/oauth',
    portfolioImportTemplateUrl: 'https://api.integration.lendingone.com/imports/examples/portfolio.xlsx',
    bridgeImportTemplateUrl: 'https://api.integration.lendingone.com/imports/examples/bridge_properties.xlsx',
    reScheduleImportTemplateUrl: 'https://api.integration.lendingone.com/imports/examples/re_schedule.xlsx',
  },
  production: {
    isProd: true,
    host: process.env.HOST || 'localhost',
    port: process.env.PORT || 8080,
    apiUrl: 'https://api.lendingone.com/v2',
    authUrl: 'https://api.lendingone.com/oauth',
    portfolioImportTemplateUrl: 'https://api.lendingone.com/imports/examples/portfolio.xlsx',
    bridgeImportTemplateUrl: 'https://api.lendingone.com/imports/examples/bridge_properties.xlsx',
    reScheduleImportTemplateUrl: 'https://api.lendingone.com/imports/examples/re_schedule.xlsx',
  },
  training: {
    isProd: true,
    host: process.env.HOST || 'localhost',
    port: process.env.PORT || 8080,
    apiUrl: 'https://api.training.lendingone.com/v2',
    authUrl: 'https://api.training.lendingone.com/oauth',
    portfolioImportTemplateUrl: 'https://api.training.lendingone.com/imports/examples/portfolio.xlsx',
    bridgeImportTemplateUrl: 'https://api.training.lendingone.com/imports/examples/bridge_properties.xlsx',
    reScheduleImportTemplateUrl: 'https://api.training.lendingone.com/imports/examples/re_schedule.xlsx',
  },
};

module.exports = R.mergeDeepRight(config.all, config[config.all.env]);
